import ApiService from "@/api/api.service";
import { AxiosPromise } from "axios";
import { PaginationResponse } from "@/types/PaginationResponse";
import { Referral } from "@/types/Referral";

export const loadReferrals = (
  page: number,
  email: string
): AxiosPromise<PaginationResponse<Referral[]>> => {
  return ApiService.query("api/admin/referrals", {
    page,
    email
  });
};
