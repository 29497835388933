






































import { Component, Vue, Watch } from "vue-property-decorator";
import { Pager } from "@/types/Pager";
import moment from "moment";
import { Referral } from "@/types/Referral";
import { loadReferrals } from "@/api/referrals";
import { convertMoney } from "@/common/helper";

@Component({
  components: {}
})
export default class Invoices extends Vue {
  data: Referral[] = [];
  pagination: Partial<Pager> = {};
  isLoading = false;
  isLoadPagination = false;
  searchEmail = "";
  moment = moment;
  debouncedLoadUsers = this.lodash.debounce(this.loadReferrals, 500);

  convertMoney = convertMoney;

  columns = [
    {
      title: "Id",
      key: "id",
      dataIndex: "id"
    },
    {
      title: "User",
      key: "user",
      dataIndex: "user",
      scopedSlots: { customRender: "user" }
    },
    {
      title: "Fee",
      key: "fee",
      dataIndex: "fee",
      scopedSlots: { customRender: "fee" }
    },
    {
      title: "Referrals",
      key: "referrals",
      dataIndex: "referrals",
      scopedSlots: { customRender: "referrals" }
    },
    {
      title: "Earned",
      key: "earned",
      dataIndex: "earned",
      scopedSlots: { customRender: "earned" }
    },
    {
      title: "Paid",
      key: "paid",
      dataIndex: "paid",
      scopedSlots: { customRender: "paid" }
    },
    {
      title: "Created",
      key: "createdAt",
      dataIndex: "createdAt",
      scopedSlots: { customRender: "createdAt" }
    },
    {
      title: "Action",
      dataIndex: "action",
      width: "20%",
      scopedSlots: { customRender: "action" }
    }
  ];

  mounted() {
    this.loadReferrals(1);
  }

  handleTableChange(pagination: Pager) {
    const pager: Partial<Pager> = { ...this.pagination };
    pager.current = pagination.current;
    this.pagination = pager;
    if (pager.current) {
      this.loadReferrals(pager.current);
    }
  }

  async loadReferrals(page: number, load = true) {
    if (load) {
      this.isLoading = true;
    }
    const { data } = await loadReferrals(page, this.searchEmail);
    this.data = data.data;
    this.isLoading = false;
    this.pagination.current = data.currentPage;
    this.pagination.pageSize = data.perPage;
    this.pagination.total = data.total;
    this.isLoadPagination = true;
  }

  @Watch("searchEmail")
  emailSearch() {
    this.debouncedLoadUsers(1);
  }
}
